<template>
  <div class="front-container">
    <div class="header-nav">
      <div class="header-left-warp">
        <div class="logo-warp">
          <div class="logo">
            <img src="../../../public/logo.svg" alt="" />
          </div>
          <div class="logo-text">{{ projectName }}</div>
        </div>
        <div class="header-navs">
          <el-menu
            router
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <!--前台路由-->
            <el-menu-item index="/front/home">首页</el-menu-item>
            <!--前台路由-->
          </el-menu>
        </div>
      </div>
      <div class="user-warp">
        <div v-if="!user.id" class="btn-login">
          <el-button @click="$router.push('/login')">登录</el-button>
        </div>
        <div v-if="!user.id" class="btn-login" style="margin-left: 10px">
          <el-button @click="$router.push('/register')">注册</el-button>
        </div>
        <el-dropdown v-else>
          <div class="user-avatar">
            <img :src="user.avatarUrl" />
          </div>
          <el-dropdown-menu size="medium" slot="dropdown">
            <el-dropdown-item>{{ user.nickname }}</el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/front/person"><div>个人信息</div></router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/front/password"
                ><div>修改密码</div></router-link
              >
            </el-dropdown-item>
            <el-dropdown-item
              ><div @click="logout">退出登录</div></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="main-content">
      <router-view @refreshUser="getUser"></router-view>
    </div>
  </div>
</template>

<script>
import { projectName } from "../../../config/config.default";
export default {
  name: "",
  data() {
    return {
      projectName: projectName,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
    };
  },
  methods: {
    handleSelect(key, keyPath) {},
    logout() {
      this.$store.commit("logout");
      this.$message.success("退出成功");
    },
    getUser() {
      let username = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).username
        : "";
      if (username) {
        // 从后台获取User数据
        this.request.get("/user/username/" + username).then((res) => {
          // 重新赋值后台的最新User数据
          this.user = res.data;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.front-container {
  background-color: var(--color-background-2);
  min-height: 100vh;
  .header-nav {
    z-index: 1800;
    position: sticky;
    top: 0;
    height: 70px;
    background-color: var(--color-background-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    .header-left-warp {
      margin-left: 20px;
      display: flex;
      align-items: center;
      .logo-warp {
        display: flex;
        align-items: center;
        .logo {
          width: 30px;
          height: 30px;
          margin-right: 5px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .logo-text {
          font-size: 22px;
          font-weight: 500;
          color: var(--color-success);
        }
      }
      .header-navs {
        margin-left: 100px;
        .el-menu {
          background: transparent;
        }
        .el-menu--horizontal {
          border: none;
        }
        .el-submenu__title {
          padding: 0;
          font-size: 17px !important;
          height: 35px;
          margin: 0 10px;
          display: flex;
          align-items: center;
        }
        .is-active {
          border-bottom: 2px solid var(--color-success);
          color: var(--color-success);
        }
      }
    }
    .user-warp {
      margin-right: 10px;
      margin-top: 5px;
      display: flex;
      .user-avatar {
        width: 40px;
        height: 40px;

        border-radius: 50%;
        overflow: hidden;
        border: 1px solid var(--color-primary);
        padding: 2px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
  .main-content {

  }
}
</style>
